@import url('https://fonts.googleapis.com/css2?family=Lobster&family=Montserrat&family=Roboto+Slab:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Norican&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.DraftEditor-root {
  width: 100%;
  min-height: 200px;
  border: 1px solid rgba(139, 139, 139);
  border-radius: 4px;
  padding: 16.5px 14px;
  color: rgb(0, 0, 0);
}
